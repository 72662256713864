<template>
<div>
  <div class="row justify-content-center my-5" id="buttons">
    <b-button-toolbar key-nav aria-label="Opciones">
      <b-button-group class="mx-1">
        <b-button variant="secondary" @click="printReport()">
          <b-icon icon="printer-fill"/> Imprimir
        </b-button>
        <b-button variant="primary" :to="{name:'home'}">
          <b-icon icon="house-door-fill"/> Inicio
        </b-button>
        <b-button variant="light" @click="goBack()">
          <b-icon icon="arrow-return-left"/> Regresar
        </b-button>
      </b-button-group>
    </b-button-toolbar>
  </div>
  <b-container id="print" v-if="ready">
    <b-table-simple hover small striped stacked>
      <b-tbody>
        <b-tr>
          <b-th class="text-white text-center bg-dark">Inspección: {{visualInspection.code}}</b-th>
        </b-tr>
        <b-table-simple hover small striped stacked caption-top bordered>
          <b-tbody>
            <b-tr>
                <b-td stacked-heading="Planta" class="text-center">
                    {{visualInspection.receiptSamples.workCenters.code}} - {{visualInspection.receiptSamples.workCenters.name}}
                </b-td>
                <b-td stacked-heading="Servicio" class="text-center">
                    {{visualInspection.receiptSamples.code}}
                </b-td>
                <b-td stacked-heading="Cliente" class="text-center">
                    {{visualInspection.receiptSamples.lab_customer }}
                </b-td>
            </b-tr>
          </b-tbody>
          <b-tbody>
            <b-tr>
                <b-td stacked-heading="Residuo de SAP" class="text-center">
                    {{visualInspection.receiptSamples.sample_material}}
                </b-td>
                <b-td stacked-heading="Residuo de Operación" class="text-center">
                    {{visualInspection.receiptSamples.sample_material }}
                </b-td>
                <b-td stacked-heading="Corriente" class="text-center">
                    {{visualInspection.receiptSamples.currents.name}}
                </b-td>
                <!-- <b-td stacked-heading="Peligrosidad" class="text-center">
                    {{visualInspection.dischargeMaterials.dangerousTypes.name }}
                </b-td> -->
            </b-tr>
          </b-tbody>
          <b-tbody>
            <b-tr>
                <b-td stacked-heading="No. Contenedores" class="text-center">
                    {{visualInspection.receiptSamples.quantity}}
                    <br>
                </b-td>
                <b-td stacked-heading="Tipo Contenedores" class="text-center">
                    {{visualInspection.receiptSamples.containerTypes.name}}
                </b-td>
                <b-td stacked-heading="Cantidad Total" class="text-center">
                    {{visualInspection.receiptSamples.quantity}}
                </b-td>
                <b-td stacked-heading="UM" class="text-center">
                    {{visualInspection.receiptSamples.ums.name}}
                </b-td>
                <b-td stacked-heading="Observaciones" class="text-center">
                    {{visualInspection.receiptSamples.notes}}
                </b-td>
            </b-tr>
          </b-tbody>
          <!-- <b-tbody>
            <b-tr>
                <b-td stacked-heading="Tipo de Manejo" class="text-center">
                    {{visualInspection.dischargeMaterials.disposalTypes.name}}
                </b-td>
            </b-tr>
          </b-tbody> -->
          <b-tbody>
            <b-tr>
                <b-td stacked-heading="Turno de Inspección Visual" class="text-center">
                    {{visualInspection.shifts.name}}
                </b-td>
                <b-td stacked-heading="Responsable de la validación" class="text-center">
                    {{visualInspection.users.name}}
                </b-td>
                <b-td stacked-heading="Fecha de Recepción" class="text-center">
                    {{moment(visualInspection.received_at).utc().format('DD/MM/YY HH:mm')}}
                </b-td>
                <b-td stacked-heading="Fecha de Inspección" class="text-center">
                    {{moment(visualInspection.inspected_at).utc().format('DD/MM/YY HH:mm')}}
                </b-td>
                <b-td stacked-heading="Estatus" class="text-center">
                    <span v-if="visualInspection.status"> Aprobado</span>
                    <span v-else> Pendiente</span>
                </b-td>
                <b-td stacked-heading="Observaciones">
                    {{visualInspection.text}}
                </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-tbody>
    </b-table-simple>
    <b-row>
        <b-col v-for="photo in visualInspection.photos" :key="photo.uid">
            <b-card :img-src="photo.url"
                :img-alt="photo.name"
                img-top
                style="max-width: 20rem;"
                class="mx-1">
            </b-card>
        </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
export default {
  name: 'visualInspectionPrint',
  data() {
    return {
      id: this.$route.params.id,
      visualInspection: {},
    }
  },
  computed: {
    ready() {
      return Object.keys(this.visualInspection).length > 0
    },
  },
  methods: {
    printReport() {
      window.print()
    },
    goBack() {
      this.$router.go(-1)
    },
    async getVisualInspection() {
      const params = {
        include: 'receiptSamples,receiptSamples.workCenters,receiptSamples.ums,receiptSamples.currents,receiptSamples.containerTypes,receiptSamples.receiptSampleDate,users,shifts',
      }
      await this.$store.dispatch('jv/get', [`visualInspections/${this.id}`, {
        params: params,
      }])
        .then(response => {
          this.visualInspection = response
          console.log('Repsonse', response)
        })
    },
  },
  async mounted() {
    await this.getVisualInspection()
  },
}
</script>

<style scoped>
  @media print {
    /* All your print styles go here */
    #buttons { display: none !important; }
  }
</style>
